@use "../../../variables.scss";

.menu-backdrop {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  z-index: 100;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: #00000080;

  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.side-menu {
  height: 100dvh;
  width: 90vw;
  pointer-events: all;

  position: fixed;
  top: 0;
  right: 0;

  background-color: variables.$background;
  z-index: 500;

  padding: 50px 20px 80px 20px;

  .close-btn {
    position: fixed;
    top: 37px;
    right: 20px;
  }

  .top-link {
    display: flex;
    align-items: center;
    width: 80%;
  }

  .links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: calc(100vh - 100px);
    overflow: scroll;
    padding-bottom: 50px;
  }

  hr {
    width: 100%;
    margin: 10px auto 10px 0;
    border: 1px solid variables.$card;
    border-bottom: none;
  }

  .side-link {
    display: flex;
    margin: 12px 0;
    align-items: center;
    width: 100%;

    .monster-head {
      margin-right: 20px;
    }
  }

  .theme-picker {
    margin-top: 20px;
    justify-content: flex-start;
  }
}