@use "../../../variables";

.text {
  color: variables.$text;
  margin: 0;
  font-family: variables.$font-primary;
  font-weight: 300;

  &.p {
    font-size: 14px;
  }

  &.h1 {
    font-size: 18px;
    font-family: variables.$font-secondary;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &.h2 {
    font-size: 12px;
    font-family: variables.$font-secondary;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &.link {
    font-size: 14px;
    color: variables.$link;
  }

  &.link-btn {
    font-size: 16px;
    color: variables.$link;
    font-family: variables.$font-secondary;
    font-weight: 500;
  }

  &.small {
    font-size: 10px;
    font-family: variables.$font-secondary;
  }

  &.hero {
    font-size: 24px;
    letter-spacing: 3px;
    font-family: variables.$font-secondary;
    margin-bottom: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &.hero-text {
    font-size: 24px;
  }

  &.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.alt {
    color: variables.$text-alt;
  }

  &.secondary {
    font-family: variables.$font-secondary;
  }

  &.align-center {
    text-align: center;
  }

  &.align-right {
    text-align: right;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}