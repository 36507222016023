@use "../../../assets/themes/light-theme.scss";
@use "../../../assets/themes/dark-theme.scss";
@use "../../../assets/themes/jungle-theme.scss";
@use "../../../assets/themes/balearic-theme.scss";
@use "../../../assets/themes/rave-theme.scss";
@use "../../../assets/themes/hippie-theme.scss";
@use "../../../assets/themes/pastel-theme.scss";
@use "../../../variables.scss";

.theme {
  background-color: variables.$background;
  margin: 0;
  padding: 0;

  width: 100vw;
}

body {
  &.theme-dark { background-color: dark-theme.$dark-background; }
  &.theme-light { background-color: light-theme.$light-background; }
  &.theme-jungle { background-color: jungle-theme.$jungle-background; }
  &.theme-balearic { background-color: balearic-theme.$balearic-background; }
  &.theme-rave { background-color: rave-theme.$rave-background; }
  &.theme-hippie { background-color: hippie-theme.$hippie-background; }
  &.theme-pastel { background-color: pastel-theme.$pastel-background; }
}

.desktop {
  .only-mobile {
    display: none;
  }
}

.mobile {
  .only-desktop {
    display: none;
  }
}