.page {
  min-height: 100dvh;
  max-height: 100dvh;
  width: 100vw;
  max-width: 900px;

  margin: 0 auto;
  padding: 104px 10px 10px 10px;
  overscroll-behavior-y: none;

  &.scrollable {
    overflow: auto;
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .page-content {
  }
}