@use "../../../variables.scss";

.monster-circle {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: clip;

  .monster {
    position: relative;
    width: 110%;
    height: 110%;

    top: -5%;
    left: -5%;
    z-index: 10;
  }

  .circle {
    position: absolute;
    top: 12%;
    left: 12%;

    width: 76%;
    height: 76%;

    border-radius: 50%;
    background-color: variables.$background;
    z-index: 0;
  }

  &.card .circle {
    background-color: variables.$card;
  }
}