@use "./variables";

html {
}

body {

  &.react-snap {
    opacity: 0;
  }

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-tap-highlight-color: transparent;

    /* Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  margin: 0;
  padding: 0;

  a {
    text-decoration: none !important;
    color: variables.$link !important;

    &:hover, &:acitve, &:visited {
    }
  }
}