@font-face {
  font-family: "Outfit";
  src: local("Outfit"),
  url("./assets/fonts/Outfit/Outfit-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
  url("./assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Space Grotesk";
  src: local("Inter"),
  url("./assets/fonts/Space Grotesk/SpaceGrotesk-VariableFont_wght.ttf") format("truetype");
}

$font-primary: Inter;
$font-secondary: "Space Grotesk";

// Theme variables mapper
$background: var(--background);
$background-00: var(--background-00);
$card: var(--card);
$card-04: var(--card-04);
$category1: var(--category1);
$category1-08: var(--category1-08);
$category1-06: var(--category1-06);
$category2: var(--category2);
$category2-08: var(--category2-08);
$category2-06: var(--category2-06);
$category3: var(--category3);
$category3-08: var(--category3-08);
$category3-06: var(--category3-06);
$category4: var(--category4);
$category4-08: var(--category4-08);
$category4-06: var(--category4-06);
$category5: var(--category5);
$category5-08: var(--category5-08);
$category5-06: var(--category5-06);
$category6: var(--category6);
$category6-08: var(--category6-08);
$category6-06: var(--category6-06);
$category7: var(--category7);
$category7-08: var(--category7-08);
$category7-06: var(--category7-06);
$text: var(--text);
$text-05: var(--text-05);
$text-02: var(--text-02);
$text-alt: var(--text-alt);
$text-alt-02: var(--text-alt-02);
$text-alt-05: var(--text-alt-05);
$link: var(--link);
$grey: var(--grey);


.background-by-category {
  &.cat1 {
    background-color: $category1-06;
  }
  &.cat2 {
    background-color: $category2-06;
  }
  &.cat3 {
    background-color: $category3-06;
  }
  &.cat4 {
    background-color: $category4-06;
  }
  &.cat5 {
    background-color: $category5-06;
  }
  &.cat6 {
    background-color: $category6-06;
  }
  &.cat7 {
    background-color: $category7-06;
  }
}