@use "../../../variables.scss";

.svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  .svg {
    width: 28px;
    height: 28px;

    fill: variables.$text;

    &.alt {
      fill: variables.$text-alt;
    }

    &.clr-link {
      fill: variables.$link;
    }

    &.clr-cat1 {
      fill: variables.$category1;
    }
    &.clr-cat2 {
      fill: variables.$category2;
    }
    &.clr-cat3 {
      fill: variables.$category3;
    }
    &.clr-cat4 {
      fill: variables.$category4;
    }
    &.clr-cat5 {
      fill: variables.$category5;
    }
    &.clr-cat6 {
      fill: variables.$category6;
    }
    &.clr-cat7 {
      fill: variables.$category7;
    }

    // no fill icons
    &.creative-commons,
    &.copyright {
      fill: transparent;
      stroke: variables.$text;
    }
  }
}