@use "../../../variables.scss";

.home-page.page {
  padding: 40px 0 60px 0;

  .section {
    padding: 30px 20px;

    .h1 {
      margin-bottom: 15px;
    }
  }

  .what {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 0;

    .share-btn {
      align-self: end;
    }

    .hero-image {
      width: 160px;
      height: 160px;
      border-radius: 80px;
      margin-bottom: 20px;
    }
  }

  .icon-h2 {
    display: flex;
    margin-bottom: 20px;
    align-items: center;

    .svg-icon {
      margin: 0 12px 0 5px;
    }
  }

  .released-wrapper {
    display: flex;
    overflow: scroll;
    margin: 0 -20px;
    padding: 0px 20px;
  }

  .timeline-section {
    background-color: variables.$category6-06;
  }

  .theme-section {
    overflow: scroll;
  }

  .dark-section {
    padding: 30px 10px;

    .content {
      display: block;
      background-color: variables.$text;
      padding: 25px;
      border-radius: 20px;

      .heading {

        display: flex;
        align-items: center;

        justify-content: space-between;
        .monster-circle {
          min-width: 120px;
        }

        .h1 {
          margin: 0;
        }
      }

      .heading + .heading {
        margin-top: 12px;
      }

      .text {
        color: variables.$text-alt
      }
      .svg {
        fill: variables.$text-alt
      }
      .heart {
        fill: variables.$category2
      }
    }
  }

  .random-links-wrapper {
    margin: 20px -20px 0 -20px;
    padding: 0px 20px;
    display: flex;
    overflow: scroll;
  }

  .wallets-wrapper {
    margin-top: 20px;
  }
}


.theme-dark .home-page.page {
  .dark-section .heart {
    fill: variables.$category3
  }
}
.theme-light .home-page.page {
  .dark-section .heart {
    fill: variables.$category4
  }
}
.theme-baby .home-page.page {}