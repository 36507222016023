@use "../../../variables.scss";

.timeline-filter {
  .filter-input-wrapper {
    position: fixed;
    top: 30px;
    left: 5dvw;
    width: 90dvw;
    height: 40px;
    z-index: 10;

    border-radius: 20px;
    padding: 8px 12px 8px 2px;

    background-color: variables.$background;
    box-shadow: 0px 0px 4px 1px variables.$text-05;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .side {
      display: flex;
      align-items: center;

      &.right a,
      &.right .svg-icon.share {
        margin-right: 4px;
      }
    }

    .filter-input {
      background-color: transparent;
      color: variables.$text;
      font-family: variables.$font-primary;
      margin: 0 5px;
      font-size: 16px;
      border: none !important;
      outline: none !important;
      max-width: calc(100dvw - 180px);
      width: 60dvw;

      &.idle::-webkit-input-placeholder,
      &.focused::-webkit-input-placeholder {
        font-weight: 200;
        opacity: 0.5;
      }

      &.filter-active::-webkit-input-placeholder {
        font-weight: 400;
        color: variables.$text
      }
    }
  }

  .filter-menu {
    position: fixed;
    top: 75px;
    left: 5dvw;
    width: 90dvw;
    max-height: 40dvh;
    z-index: 10;

    border-radius: 20px;
    padding: 11px;
    padding-left: 37px;

    background-color: variables.$background;
    box-shadow: 0px 0px 4px 1px variables.$text-05;

    overflow: scroll;

    .filter-item {
      display: flex;
      padding: 6px 0px;
      align-items: center;

      .small {
        margin-left: 8px;
        padding: 3px 6px;
        background-color: variables.$card;
        border-radius: 20px;

        &.speciality { background-color: variables.$category2; }
        &.nationality { background-color: variables.$category1; }
        &.person { background-color: variables.$category3; }
        &.other { background-color: variables.$category7; }

      }
    }

  }
}

.theme-light .timeline-filter .filter-item {
  .person.text { color: variables.$text; }
}

.theme-dark .timeline-filter .filter-item {
  .nationality.text { color: variables.$text; }
  .speciality.text { color: variables.$text; }
  .active.cat4.text { color: variables.$text; }
}

.theme-jungle .timeline-filter .filter-item {
  .nationality.text { color: variables.$text; }
  .speciality.text { color: variables.$text; }
  .active.cat4.text { color: variables.$text; }
  .active.cat5.text { color: variables.$text; }
  .other.text { color: variables.$text; }
}

.theme-balearic .timeline-filter .filter-item {
  .person.text { color: variables.$text; }
}

.theme-rave .timeline-filter .filter-item {
  .speciality.text { color: variables.$text;   }
  .active.cat4.text { color: variables.$text; }
}

.theme-hippie .timeline-filter .filter-item {
  .person.text { color: variables.$text; }
}

.theme-pastel .timeline-filter .filter-item {
  .nationality.text { color: variables.$text; }
  .speciality.text { color: variables.$text; }
  .other.text { color: variables.$text; }
  .person.text { color: variables.$text; }
  .active.text { color: variables.$text; }
}
