@use "../../variables";

$light-background: #FFFFFF;
$light-background-00: #FFFFFF00;
$light-card: #F5F5F5;
$light-card-04: #F5F5F540;

$light-text: #000000;
$light-text-05: #00000050;
$light-text-02: #00000020;
$light-text-alt: #FFFFFF;
$light-text-alt-02: #FFFFFF20;
$light-text-alt-05: #FFFFFF50;
$light-link: #5C25CF;

$light-category1: #25D174;
$light-category1-08: #25D17480;
$light-category1-06: #25D17460;
$light-category2: #FD2424;
$light-category2-08: #FD242480;
$light-category2-06: #FD242460;
$light-category3: #D0F103;
$light-category3-08: #D0F10380;
$light-category3-06: #D0F10360;
$light-category4: #5C25CF;
$light-category4-08: #5C25CF80;
$light-category4-06: #5C25CF60;
$light-category5: #FF9900;
$light-category5-08: #FF990080;
$light-category5-06: #FF990060;
$light-category6: #5BE02D;
$light-category6-08: #5BE02D80;
$light-category6-06: #5BE02D60;
$light-category7: #E910D3;
$light-category7-08: #E910D380;
$light-category7-06: #E910D360;

$light-grey: #F5F5F5;

.theme.theme-light {
  --background: #{$light-background};
  --background-00: #{$light-background-00};
  --card: #{$light-card};
  --card-04: #{$light-card-04};
  --category1: #{$light-category1};
  --category1-08: #{$light-category1-08};
  --category1-06: #{$light-category1-06};
  --category2: #{$light-category2};
  --category2-08: #{$light-category2-08};
  --category2-06: #{$light-category2-06};
  --category3: #{$light-category3};
  --category3-08: #{$light-category3-08};
  --category3-06: #{$light-category3-06};
  --category4: #{$light-category4};
  --category4-08: #{$light-category4-08};
  --category4-06: #{$light-category4-06};
  --category5: #{$light-category5};
  --category5-08: #{$light-category5-08};
  --category5-06: #{$light-category5-06};
  --category6: #{$light-category6};
  --category6-08: #{$light-category6-08};
  --category6-06: #{$light-category6-06};
  --category7: #{$light-category7};
  --category7-08: #{$light-category7-08};
  --category7-06: #{$light-category7-06};
  --text: #{$light-text};
  --text-05: #{$light-text-05};
  --text-02: #{$light-text-02};
  --text-alt: #{$light-text-alt};
  --text-alt-05: #{$light-text-alt-05};
  --text-alt-02: #{$light-text-alt-02};
  --link: #{$light-link};
  --grey: #{$light-grey};
}