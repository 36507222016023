@use "../../../variables.scss";

.released-episode {
  display: flex;
  flex-direction: column;
  align-items: center;

  .svg-icon {
    margin-top: 6px;
  }
}

.released-episode + .released-episode {
  margin-left: 15px;
}