@use "../../../variables.scss";

.monster-head {
  overflow: hidden;
  position: relative;
  isolation: isolate;

  .monster-image {
    position: absolute;
  }

  &.card {
    background-color: variables.$card;
  }

  &.cat-3 {
    background-color: variables.$category3;
  }

  &.cat-2 {
    background-color: variables.$category2;
  }
}