@use "../../variables";

$pastel-background: #FCEEEC;
$pastel-background-00: #FCEEEC00;
$pastel-card: #F8E7E2;
$pastel-card-04: #F8E7E240;

$pastel-text: #575757;
$pastel-text-05: #57575750;
$pastel-text-02: #57575720;
$pastel-text-alt: #FCEEEC;
$pastel-text-alt-02: #FCEEEC20;
$pastel-text-alt-05: #FCEEEC50;
$pastel-link: #FFB270;

$pastel-category1: #A4D4FF;
$pastel-category1-08: #A4D4FF95;
$pastel-category1-06: #A4D4FF85;
$pastel-category2: #FFC2E2;
$pastel-category2-08: #FFC2E295;
$pastel-category2-06: #FFC2E285;
$pastel-category3: #FFF89C;
$pastel-category3-08: #FFF89C95;
$pastel-category3-06: #FFF89C85;
$pastel-category4: #E2D9F0;
$pastel-category4-08: #E2D9F095;
$pastel-category4-06: #E2D9F085;
$pastel-category5: #FFAD8A;
$pastel-category5-08: #FFAD8A95;
$pastel-category5-06: #FFAD8A85;
$pastel-category6: #B5EAD7;
$pastel-category6-08: #B5EAD795;
$pastel-category6-06: #B5EAD785;
$pastel-category7: #FFDAB9;
$pastel-category7-08: #FFDAB995;
$pastel-category7-06: #FFDAB985;

$pastel-grey: #161529;

.theme.theme-pastel {
  --background: #{$pastel-background};
  --background-00: #{$pastel-background-00};
  --card: #{$pastel-card};
  --card-04: #{$pastel-card-04};
  --category1: #{$pastel-category1};
  --category1-08: #{$pastel-category1-08};
  --category1-06: #{$pastel-category1-06};
  --category2: #{$pastel-category2};
  --category2-08: #{$pastel-category2-08};
  --category2-06: #{$pastel-category2-06};
  --category3: #{$pastel-category3};
  --category3-08: #{$pastel-category3-08};
  --category3-06: #{$pastel-category3-06};
  --category4: #{$pastel-category4};
  --category4-08: #{$pastel-category4-08};
  --category4-06: #{$pastel-category4-06};
  --category5: #{$pastel-category5};
  --category5-08: #{$pastel-category5-08};
  --category5-06: #{$pastel-category5-06};
  --category6: #{$pastel-category6};
  --category6-08: #{$pastel-category6-08};
  --category6-06: #{$pastel-category6-06};
  --category7: #{$pastel-category7};
  --category7-08: #{$pastel-category7-08};
  --category7-06: #{$pastel-category7-06};
  --text: #{$pastel-text};
  --text-05: #{$pastel-text-05};
  --text-02: #{$pastel-text-02};
  --text-alt: #{$pastel-text-alt};
  --text-alt-05: #{$pastel-text-alt-05};
  --text-alt-02: #{$pastel-text-alt-02};
  --link: #{$pastel-link};
  --grey: #{$pastel-grey};
}