@use "../../../variables.scss";

.random-link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  min-width: 180px;
  width: auto;
  min-height: 150px;
  border-radius: 21px;

  @extend .background-by-category;

  .svg-icon {
    align-self: flex-end;
  }
}

.random-link + .random-link {
  margin-left: 10px;
}