@use "../../../variables.scss";

.artwork {
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 1000;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: #00000080;

  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);

  display: flex;
  align-items: center;
  justify-content: center;

  //padding: 30px;

  .react-transform-wrapper {
    overflow: visible;
  }

  .artwork-image {
    max-width: 100vw;
    max-height: 100dvh;
  }

  .close-btn {
    width: 48px;
    height: 48px;
    border-radius: 32px;

    position: fixed;
    top: calc(100% - 78px);
    right: 30px;

    z-index: 20000;

    background-color: variables.$background;

    display: flex;
    align-items: center;
    justify-content: center;

  }

  .copyright-text {
    display: flex;
    align-items: center;
    padding: 3px 12px 3px 8px;
    border-radius: 20px;

    background-color: variables.$background;

    position: fixed;
    top: calc(100dvh - 63px);
    left: 30px;

    .svg-icon {
      margin-right: 6px;
    }
  }
}

.theme-dark .artwork {}
.theme-light .artwork {}
.theme-baby .artwork {
  .artwork-footer {
    .text {
      color: variables.$text-alt;
    }

    .svg {
      fill: variables.$text-alt;
      stroke: variables.$text-alt;
    }
  }
}