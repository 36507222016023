@use "../../../variables.scss";

.filter-pills {
  padding: 20px;

  width: 100vw;
  background-color: variables.$background;
  position: fixed;
  top: 90px;
  left: 0px;
  z-index: 10;

  &.fixed {
  }

  &.static {
    position: static;
  }

  .pills-wrapper {
    display: flex;
    justify-content: flex-start;

    &.scrollable {
      justify-content: flex-start;
      overflow-x: scroll;
    }
  }
}

.desktop .filter-pills {
  padding: 0;
  position: static;
  width: auto;

  .pills-wrapper {
    justify-content: flex-start;
  }
}