@use "../../../variables.scss";

.newest-episode {
  padding: 20px;
  background-color: variables.$category6-06;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .ep-content {
    display: flex;
    justify-content: space-between;
  }

  .half {
    overflow: hidden;
    min-height: 140px;
  }

  .half.image {
    min-width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .ep-image {
    max-width: 70px;
    border-radius: 50%;
    background-color: variables.$background;
  }

  @extend .background-by-category;
}

.newest-episode + .newest-episode {
  margin-top: 20px;
}