@use "../../../variables.scss";

.timeline-pill {
  border-radius: 10px;
  background-color: variables.$background;
  border: 1px solid variables.$background;
  display: block;
  padding: 2px;
  z-index: 5;

  transition:
          border-color 1s,
          background-color 1s;

  .pill-content {
    display: flex;
    align-items: center;
    height: 100%;

    .monster-head {
      flex: none;
    }
  }

  &.link {
    background-color: variables.$link;
    border-color: variables.$link;
  }

  &.lvl-3 {
    background-color: variables.$category5;
    border-color: variables.$category5;
  }

  &.lvl-4 {
    background-color: variables.$category7;
    border-color: variables.$category7;
  }

  &.lvl-5 {
    background-color: variables.$category6;
    border-color: variables.$category6;
  }

  &.is-alive {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &.is-filtered {
    background-color: variables.$category3;
    border-color: variables.$category3;
  }

  &.is-selected {
    z-index: 100;

    &:not(.small-bubble) {
      //border-bottom-left-radius: 0px !important;
      //border-bottom-right-radius: 0px !important;
    }

    &.small-bubble {
      .bubble {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
      }
    }
  }

  .bubble {
    position: relative;
    width: 250px;
    left: min(calc(50% - 125px), 200px);
    top: -7px;
    background-color: variables.$background;

    transform-origin: top;
    transition: opacity 0.1s;

    padding: 16px;
    border-radius: 13px;

    .bubble-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .names {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .name {
        margin-right: 6px;
      }
    }

    .text {
      font-size: 12px;
      color: variables.$text;
    }

    .bubble-content {
      margin: 16px 0;
    }

    .footer-links {
      display: flex;
      align-items: flex-start;

      span {
        color: variables.$text-05
      }


      .svg-icon {
        margin-right: 12px;
      }
    }

    .footer-links + .footer-links {
      margin-top: 6px;
    }

    &.bubble-right {
      border-top-right-radius: 0px;
    }
  }

  .pill-text {
    margin: -1px 5px;
    opacity: 1;
    transition: opacity 0.5s;
  }

  &.xs .pill-text {
    opacity: 0;
  }
}

.theme-light .timeline-pill {
  &.lvl-5 .text { color: variables.$text-alt }
  &.lvl-4 .text { color: variables.$text-alt }
  &.lvl-3 .text { color: variables.$text-alt }
  &.link.is-filtered .text { color: variables.$text; }
  &.link .text { color: variables.$text-alt }
  &.is-selected .text { color: variables.$text }
}

.theme-dark .timeline-pill {
  &.lvl-5 .text { color: variables.$text-alt }
  &.lvl-4 .text { color: variables.$text }
  &.lvl-3 .text { color: variables.$text }
  &.is-filtered .text { color: variables.$text-alt; }
  &.link .text { color: variables.$text-alt; }
  .bubble .text { color: variables.$text; }
}

.theme-jungle .timeline-pill {
  &.lvl-5 .text { color: variables.$text }
  &.lvl-4 .text { color: variables.$text }
  &.lvl-3 .text { color: variables.$text }
  &.is-filtered .text { color: variables.$text-alt; }
  &.link .text { color: variables.$text; }
  .bubble .text { color: variables.$text; }
}

.theme-balearic .timeline-pill {
  &.lvl-5 .text { color: variables.$text-alt }
  &.lvl-4 .text { color: variables.$text-alt }
  &.lvl-3 .text { color: variables.$text-alt }
  &.link .text { color: variables.$text-alt; }
  &.is-filtered .text { color: variables.$text; }
  &.link.is-selected .text { color: variables.$text; }
  .bubble .text { color: variables.$text }
}

.theme-rave .timeline-pill {
  &.lvl-5 .text { color: variables.$text-alt }
  &.lvl-4 .text { color: variables.$text-alt }
  &.lvl-3 .text { color: variables.$text-alt }
  &.is-filtered .text { color: variables.$text-alt; }
  &.is-filtered .bubble .text { color: variables.$text; }
  .bubble .text { color: variables.$text }
}

.theme-hippie .timeline-pill {
  &.lvl-5 .text { color: variables.$text-alt }
  &.lvl-4 .text { color: variables.$text-alt }
  &.lvl-3 .text { color: variables.$text-alt }
  &.link .text { color: variables.$text-alt; }
  &.is-filtered .text { color: variables.$text; }
  &.link.is-selected .text { color: variables.$text; }
  .bubble .text { color: variables.$text }
}

