@use "../../../variables.scss";

.bio-card {
  background-color: variables.$card;

  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 200;

  padding: 20px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  .h1 span {
    font-weight: 300;
  }

  .card-footer {
    display: flex;
    align-items: center;

    .chevron-left-link {
      margin-right: 7px;
    }

    .chevron-right-link {
      margin-left: auto;
    }
  }

  .card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .events {
    margin: -10px 0 15px 0;
    max-height: 150px;
    overflow: scroll;
    width: calc(100% - 20px);
  }

  .event {
    padding: 4px 0;
    display: flex;
    align-items: flex-start;

    p.text {
      max-width: calc(100vw - 90px);
    }

    .svg-icon {
      margin-top: 3px;
      margin-right: 5px;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 6px;
      margin-right: 14px;
      margin-left: 8px;
      margin-top: 4px;
      background-color: variables.$text;

      &.cat1 {
        background-color: variables.$category1;
      }
      &.cat2 {
        background-color: variables.$category2;
      }
      &.cat3 {
        background-color: variables.$category3;
      }
      &.cat4 {
        background-color: variables.$category4;
      }
      &.cat5 {
        background-color: variables.$category5;
      }
      &.cat6 {
        background-color: variables.$category6;
      }
      &.cat7 {
        background-color: variables.$category7;
      }
    }


  }

  .networked-event {
    hr {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 8px;
      border: 1px solid variables.$text;
      border-bottom: none;
      opacity: 0.1;
    }
  }

  &.general {
    .events {
      max-height: none;
    }

    .general-links {
      display: flex;
      align-items: flex-start;
      margin-top: 6px;

      span {
        color: variables.$text-05
      }


      .svg-icon {
        margin-right: 12px;
      }
    }
  }
}


.theme-dark .bio-card {}
.theme-light .bio-card {}
.theme-baby .bio-card {
  .text {
    color: variables.$text-alt;
  }
  .svg {
    fill: variables.$text-alt;
    stroke: variables.$text-alt;
  }
}

.desktop .bio-card {
  width: 100%;
  position: relative;

  padding: 20px 20px;

  display: block;
  border-radius: 15px;

  margin: 20px 0;
  min-height: 300px;

  .events {
    max-height: 180px;
  }

  .event .text {
    width: 230px;
  }

  .card-footer {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 40px);
  }
}