@use "../../../variables.scss";

.timeline-grid {
  width: 100dvw;
  height: 100dvh;

  position: fixed;
  top: 0;
  left: 0;

  background-color: variables.$card;
  z-index: -1;
  pointer-events: none;

  &.years {
    z-index: 200;
    background-color: transparent;
  }

  .timeline-years {
    padding: 8px 0;
    height: 66px;
    overflow: hidden;

    position: absolute;
    top: calc(100dvh - 66px);
    z-index: 200;

    .text {
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }

    .year-wrapper {
      background-color: variables.$background;
      border-radius: 12px;
      box-shadow: 0px 0px 4px 1px variables.$text-05;
      width: 28px;
      height: 54px;
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .timeline-background {
    position: absolute;
    background-color: variables.$card;
    display: flex;

    .vertical-line {
      width: 0px;
      border-left: 1px solid;
      border-right: 1px solid;
      opacity: 0.3;

      position: absolute;

      &.main {
        border-color: variables.$text-05;
      }
      &.helper {
        border-color: variables.$text-02;
      }
      &.current {
        border-color: variables.$link;
      }
    }
  }
}