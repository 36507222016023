@use "../../variables";

$jungle-background: #3B5323;
$jungle-background-00: #3B532300;
$jungle-card: #8FBC8F;
$jungle-card-04: #8FBC8F40;

$jungle-text: #FFFFFF;
$jungle-text-05: #FFFFFF50;
$jungle-text-02: #FFFFFF20;
$jungle-text-alt: #3B5323;
$jungle-text-alt-02: #3B532320;
$jungle-text-alt-05: #3B532350;
$jungle-link: #FFD700;

$jungle-category1: #1E90FF;
$jungle-category1-08: #1E90FF80;
$jungle-category1-06: #1E90FF60;
$jungle-category2: #FF0000;
$jungle-category2-08: #FF000080;
$jungle-category2-06: #FF000060;
$jungle-category3: #FFFF00;
$jungle-category3-08: #FFFF0080;
$jungle-category3-06: #FFFF0060;
$jungle-category4: #800080;
$jungle-category4-08: #80008080;
$jungle-category4-06: #80008060;
$jungle-category5: #FF8C00;
$jungle-category5-08: #FF8C0080;
$jungle-category5-06: #FF8C0060;
$jungle-category6: #32CD32;
$jungle-category6-08: #32CD3280;
$jungle-category6-06: #32CD3260;
$jungle-category7: #FF1493;
$jungle-category7-08: #FF149380;
$jungle-category7-06: #FF149360;

$jungle-grey: #161529;

.theme.theme-jungle {
  --background: #{$jungle-background};
  --background-00: #{$jungle-background-00};
  --card: #{$jungle-card};
  --card-04: #{$jungle-card-04};
  --category1: #{$jungle-category1};
  --category1-08: #{$jungle-category1-08};
  --category1-06: #{$jungle-category1-06};
  --category2: #{$jungle-category2};
  --category2-08: #{$jungle-category2-08};
  --category2-06: #{$jungle-category2-06};
  --category3: #{$jungle-category3};
  --category3-08: #{$jungle-category3-08};
  --category3-06: #{$jungle-category3-06};
  --category4: #{$jungle-category4};
  --category4-08: #{$jungle-category4-08};
  --category4-06: #{$jungle-category4-06};
  --category5: #{$jungle-category5};
  --category5-08: #{$jungle-category5-08};
  --category5-06: #{$jungle-category5-06};
  --category6: #{$jungle-category6};
  --category6-08: #{$jungle-category6-08};
  --category6-06: #{$jungle-category6-06};
  --category7: #{$jungle-category7};
  --category7-08: #{$jungle-category7-08};
  --category7-06: #{$jungle-category7-06};
  --text: #{$jungle-text};
  --text-05: #{$jungle-text-05};
  --text-02: #{$jungle-text-02};
  --text-alt: #{$jungle-text-alt};
  --text-alt-05: #{$jungle-text-alt-05};
  --text-alt-02: #{$jungle-text-alt-02};
  --link: #{$jungle-link};
  --grey: #{$jungle-grey};
}