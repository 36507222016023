@use "../../variables";

$dark-background: #000000;
$dark-background-00: #00000000;
$dark-card: #2B1D65;
$dark-card-04: #2B1D6540;

$dark-text: #FFFFFF;
$dark-text-05: #FFFFFF50;
$dark-text-02: #FFFFFF20;
$dark-text-alt: #000000;
$dark-text-alt-02: #00000020;
$dark-text-alt-05: #00000050;

$dark-category1: #005BE3;
$dark-category1-08: #005BE380;
$dark-category1-06: #005BE360;
$dark-category2: #F82C78;
$dark-category2-08: #F82C7880;
$dark-category2-06: #F82C7860;
$dark-category3: #F1F103;
$dark-category3-08: #F1F10380;
$dark-category3-06: #F1F10360;
$dark-category4: #E728FC;
$dark-category4-08: #E728FC80;
$dark-category4-06: #E728FC60;
$dark-category5: #FF8310;
$dark-category5-08: #FF831080;
$dark-category5-06: #FF831060;
$dark-category6: #60F41A;
$dark-category6-08: #60F41A80;
$dark-category6-06: #60F41A60;
$dark-category7: #00D293;
$dark-category7-08: #00D29380;
$dark-category7-06: #00D29360;

$dark-link: #0CA3E9;
$dark-grey: #161529;

.theme.theme-dark {
  --background: #{$dark-background};
  --background-00: #{$dark-background-00};
  --card: #{$dark-card};
  --card-04: #{$dark-card-04};
  --category1: #{$dark-category1};
  --category1-08: #{$dark-category1-08};
  --category1-06: #{$dark-category1-06};
  --category2: #{$dark-category2};
  --category2-08: #{$dark-category2-08};
  --category2-06: #{$dark-category2-06};
  --category3: #{$dark-category3};
  --category3-08: #{$dark-category3-08};
  --category3-06: #{$dark-category3-06};
  --category4: #{$dark-category4};
  --category4-08: #{$dark-category4-08};
  --category4-06: #{$dark-category4-06};
  --category5: #{$dark-category5};
  --category5-08: #{$dark-category5-08};
  --category5-06: #{$dark-category5-06};
  --category6: #{$dark-category6};
  --category6-08: #{$dark-category6-08};
  --category6-06: #{$dark-category6-06};
  --category7: #{$dark-category7};
  --category7-08: #{$dark-category7-08};
  --category7-06: #{$dark-category7-06};
  --text: #{$dark-text};
  --text-05: #{$dark-text-05};
  --text-02: #{$dark-text-02};
  --text-alt: #{$dark-text-alt};
  --text-alt-05: #{$dark-text-alt-05};
  --text-alt-02: #{$dark-text-alt-02};
  --link: #{$dark-link};
  --grey: #{$dark-grey};
}