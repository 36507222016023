@use "../../variables";

$balearic-background: #F8F1E8;
$balearic-background-00: #F8F1E800;
$balearic-card: #D9E6ED;
$balearic-card-04: #D9E6ED40;

$balearic-text: #2A2A2A;
$balearic-text-05: #2A2A2A50;
$balearic-text-02: #2A2A2A20;
$balearic-text-alt: #F8F1E8;
$balearic-text-alt-02: #F8F1E820;
$balearic-text-alt-05: #F8F1E850;
$balearic-link: #FF6B6B;

$balearic-category1: #00B4D8;
$balearic-category1-08: #00B4D880;
$balearic-category1-06: #00B4D860;
$balearic-category2: #FF6F61;
$balearic-category2-08: #FF6F6180;
$balearic-category2-06: #FF6F6160;
$balearic-category3: #FFD23F;
$balearic-category3-08: #FFD23F80;
$balearic-category3-06: #FFD23F60;
$balearic-category4: #8A4F7D;
$balearic-category4-08: #8A4F7D80;
$balearic-category4-06: #8A4F7D60;
$balearic-category5: #FFA61A;
$balearic-category5-08: #FFA61A80;
$balearic-category5-06: #FFA61A60;
$balearic-category6: #0ACF83;
$balearic-category6-08: #0ACF8380;
$balearic-category6-06: #0ACF8360;
$balearic-category7: #5AB9EA;
$balearic-category7-08: #5AB9EA80;
$balearic-category7-06: #5AB9EA60;

$balearic-grey: #161529;

.theme.theme-balearic {
  --background: #{$balearic-background};
  --background-00: #{$balearic-background-00};
  --card: #{$balearic-card};
  --card-04: #{$balearic-card-04};
  --category1: #{$balearic-category1};
  --category1-08: #{$balearic-category1-08};
  --category1-06: #{$balearic-category1-06};
  --category2: #{$balearic-category2};
  --category2-08: #{$balearic-category2-08};
  --category2-06: #{$balearic-category2-06};
  --category3: #{$balearic-category3};
  --category3-08: #{$balearic-category3-08};
  --category3-06: #{$balearic-category3-06};
  --category4: #{$balearic-category4};
  --category4-08: #{$balearic-category4-08};
  --category4-06: #{$balearic-category4-06};
  --category5: #{$balearic-category5};
  --category5-08: #{$balearic-category5-08};
  --category5-06: #{$balearic-category5-06};
  --category6: #{$balearic-category6};
  --category6-08: #{$balearic-category6-08};
  --category6-06: #{$balearic-category6-06};
  --category7: #{$balearic-category7};
  --category7-08: #{$balearic-category7-08};
  --category7-06: #{$balearic-category7-06};
  --text: #{$balearic-text};
  --text-05: #{$balearic-text-05};
  --text-02: #{$balearic-text-02};
  --text-alt: #{$balearic-text-alt};
  --text-alt-05: #{$balearic-text-alt-05};
  --text-alt-02: #{$balearic-text-alt-02};
  --link: #{$balearic-link};
  --grey: #{$balearic-grey};
}