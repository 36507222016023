@use "../../../variables.scss";

.bio-year {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;

  .cats-1 {
    width: 28px;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cats-2 {
    width: 42px;
    height: 28px;
    position: relative;
  }

  .cats-3,
  .cats-4 {
    width: 42px;
    height: 42px;
    position: relative;
  }

  .cats-5 {
    width: 48px;
    height: 48px;
    position: relative;
  }

  .circle {
    border-radius: 50%;
    border: 1px solid;
    position: absolute;

    -webkit-transition: background-color 0.4s, border-color 0.4s, box-shadow 0.4s, -webkit-filter 0.4s;
  }

  .cat-1 {
    background-color: variables.$category1-06;
    border-color: variables.$category1;
  }
  .cat-2 {
    background-color: variables.$category2-06;
    border-color: variables.$category2;
  }
  .cat-3 {
    background-color: variables.$category3-06;
    border-color: variables.$category3;
  }
  .cat-4 {
    background-color: variables.$category4-06;
    border-color: variables.$category4;
  }
  .cat-5 {
    background-color: variables.$category5-06;
    border-color: variables.$category5;
  }
  .cat-6 {
    background-color: variables.$category6-06;
    border-color: variables.$category6;
  }
  .cat-7 {
    background-color: variables.$category7-06;
    border-color: variables.$category7;
  }


  &.active {
    filter: drop-shadow(0px 0px 3px variables.$link);
    .circle {
      border: 2px solid variables.$link;
    }

    .cat-1 {
      background-color: variables.$category1;
      border-color: variables.$category1;
    }
    .cat-2 {
      background-color: variables.$category2;
      border-color: variables.$category2;
    }
    .cat-3 {
      background-color: variables.$category3;
      border-color: variables.$category3;
    }
    .cat-4 {
      background-color: variables.$category4;
      border-color: variables.$category4;
    }
    .cat-5 {
      background-color: variables.$category5;
      border-color: variables.$category5;
    }
    .cat-6 {
      background-color: variables.$category6;
      border-color: variables.$category6;
    }
    .cat-7 {
      background-color: variables.$category7;
      border-color: variables.$category7;
    }
  }
}

.filter-cat1.bio-year .cat-1 {
  z-index: 2;
  box-shadow: 0px 0px 4px 1px variables.$link;
  border: 2px solid variables.$text;
  background-color: variables.$category1;
  border-color: variables.$category1;
}
.filter-cat2.bio-year .cat-2 {
  z-index: 2;
  box-shadow: 0px 0px 4px 1px variables.$link;
  border: 2px solid variables.$text;
  background-color: variables.$category2;
  border-color: variables.$category2;
}
.filter-cat3.bio-year .cat-3 {
  z-index: 2;
  box-shadow: 0px 0px 4px 1px variables.$link;
  border: 2px solid variables.$text;
  background-color: variables.$category3;
  border-color: variables.$category3;
}
.filter-cat4.bio-year .cat-4 {
  z-index: 2;
  box-shadow: 0px 0px 4px 1px variables.$link;
  border: 2px solid variables.$text;
  background-color: variables.$category4;
  border-color: variables.$category4;
}
.filter-cat5.bio-year .cat-5 {
  z-index: 2;
  box-shadow: 0px 0px 4px 1px variables.$link;
  border: 2px solid variables.$text;
  background-color: variables.$category5;
  border-color: variables.$category5;
}
.filter-cat6.bio-year .cat-6 {
  z-index: 2;
  box-shadow: 0px 0px 4px 1px variables.$link;
  border: 2px solid variables.$text;
  background-color: variables.$category6;
  border-color: variables.$category6;
}
.filter-cat7.bio-year .cat-7 {
  z-index: 2;
  box-shadow: 0px 0px 4px 1px variables.$link;
  border: 2px solid variables.$text;
  background-color: variables.$category7;
  border-color: variables.$category7;
}
