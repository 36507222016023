@use "../../../variables.scss";

.top-nav {
  width: 100vw;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  overflow: visible;
  padding: 20px 20px 10px 20px;
  background-color: var(--background);
  z-index: 1000;

  .header-wrapper {
    display: flex;
    align-items: center;
  }

  .text-wrapper {
    margin-left: 15px;

    .h1 {
      margin-bottom: 4px;
      letter-spacing: 0px;
    }

  }

  .monster-head {
    flex: none;
  }

  .image {
    width: 62px;
    height: 62px;

    //border: 1px solid variables.$text;
    border-radius: 32px;
    background-color: variables.$card;
  }

  .hamburger {
    width: 28px;
    height: 28px;
  }

  .nav-btns {
    display: flex;
    align-items: center;

    .share-btn {
      margin-right: 15px;
    }
  }
}

.desktop .top-nav {
  left: auto;
  margin-left: -10px;
}
