@use "../../../variables.scss";
@use "../../../assets/themes/light-theme.scss";
@use "../../../assets/themes/dark-theme.scss";
@use "../../../assets/themes/jungle-theme.scss";
@use "../../../assets/themes/balearic-theme.scss";
@use "../../../assets/themes/rave-theme.scss";
@use "../../../assets/themes/hippie-theme.scss";
@use "../../../assets/themes/pastel-theme.scss";

.theme-picker {
  display: flex;
  justify-content: space-between;

  .theme-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    align-items: center;
    margin: 0 3px;

    &.active .circle-wrapper {
      border: 2px solid variables.$link;
      filter: drop-shadow(0px 0px 5px variables.$link);
    }

    .circle-wrapper {
      border: 2px solid transparent;
      overflow: hidden;
      transform: rotate(45deg);

      display: flex;

      margin-bottom: 8px;
    }

    .text {
      margin-top: 4px;
    }

    &.light .background { background-color: light-theme.$light-background; }
    &.light .cat1 { background-color: light-theme.$light-category1; }
    &.light .cat2 { background-color: light-theme.$light-category2; }
    &.light .cat3 { background-color: light-theme.$light-category3; }

    &.dark .background { background-color: dark-theme.$dark-background; }
    &.dark .cat1 { background-color: dark-theme.$dark-category1; }
    &.dark .cat2 { background-color: dark-theme.$dark-category2; }
    &.dark .cat3 { background-color: dark-theme.$dark-category3; }

    &.jungle .background { background-color: jungle-theme.$jungle-background; }
    &.jungle .cat1 { background-color: jungle-theme.$jungle-category1; }
    &.jungle .cat2 { background-color: jungle-theme.$jungle-category2; }
    &.jungle .cat3 { background-color: jungle-theme.$jungle-category3; }

    &.balearic .background { background-color: balearic-theme.$balearic-background; }
    &.balearic .cat1 { background-color: balearic-theme.$balearic-category1; }
    &.balearic .cat2 { background-color: balearic-theme.$balearic-category2; }
    &.balearic .cat3 { background-color: balearic-theme.$balearic-category3; }

    &.rave .background { background-color: rave-theme.$rave-background; }
    &.rave .cat1 { background-color: rave-theme.$rave-category1; }
    &.rave .cat2 { background-color: rave-theme.$rave-category2; }
    &.rave .cat3 { background-color: rave-theme.$rave-category3; }

    &.hippie .background { background-color: hippie-theme.$hippie-background; }
    &.hippie .cat1 { background-color: hippie-theme.$hippie-category1; }
    &.hippie .cat2 { background-color: hippie-theme.$hippie-category2; }
    &.hippie .cat3 { background-color: hippie-theme.$hippie-category3; }

    &.pastel .background { background-color: pastel-theme.$pastel-background; }
    &.pastel .cat1 { background-color: pastel-theme.$pastel-category1; }
    &.pastel .cat2 { background-color: pastel-theme.$pastel-category2; }
    &.pastel .cat3 { background-color: pastel-theme.$pastel-category3; }
  }
}