@use "../../../variables";

.years-grid {
  margin-top: 75px;
  margin-bottom: 50px;

  transition: padding-bottom 0.3s ease-in;

  .year-line {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-size: 12px;
    }

    .text-wrapper {
      width: 30px;
    }

    .line-dots {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 300px;
      position: relative;
    }

    .dot-wrapper {
      width: 50px;
      height: 50px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        z-index: 5;
      }

      &.invisible {
        opacity: 0;
      }

      .text {
        z-index: 2;
        //color: variables.$grey;
        font-size: 10px;
        pointer-events: none;
      }
    }

    .dot {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background-color: variables.$text-02;
    }

    .line {
      height: 0px;
      position: absolute;
      top: calc(50% - 1px);
      border: 1px solid variables.$text-02;
      width: 60px;
    }

    .bd-year {
      width: 50px;
    }
  }

}