@use "../../variables";

$hippie-background: #FDF6E3;
$hippie-background-00: #FDF6E300;
$hippie-card: #FFE4C4;
$hippie-card-04: #FFE4C440;

$hippie-text: #000000;
$hippie-text-05: #00000050;
$hippie-text-02: #00000020;
$hippie-text-alt: #FDF6E3;
$hippie-text-alt-02: #FDF6E320;
$hippie-text-alt-05: #FDF6E350;
$hippie-link: #FF1493;

$hippie-category1: #00BFFF;
$hippie-category1-08: #00BFFF80;
$hippie-category1-06: #00BFFF60;
$hippie-category2: #FF4500;
$hippie-category2-08: #FF450080;
$hippie-category2-06: #FF450060;
$hippie-category3: #FFD700;
$hippie-category3-08: #FFD70080;
$hippie-category3-06: #FFD70060;
$hippie-category4: #9932CC;
$hippie-category4-08: #9932CC80;
$hippie-category4-06: #9932CC60;
$hippie-category5: #FF8C00;
$hippie-category5-08: #FF8C0080;
$hippie-category5-06: #FF8C0060;
$hippie-category6: #32CD32;
$hippie-category6-08: #32CD3280;
$hippie-category6-06: #32CD3260;
$hippie-category7: #FF00FF;
$hippie-category7-08: #FF00FF80;
$hippie-category7-06: #FF00FF60;

$hippie-grey: #161529;

.theme.theme-hippie {
  --background: #{$hippie-background};
  --background-00: #{$hippie-background-00};
  --card: #{$hippie-card};
  --card-04: #{$hippie-card-04};
  --category1: #{$hippie-category1};
  --category1-08: #{$hippie-category1-08};
  --category1-06: #{$hippie-category1-06};
  --category2: #{$hippie-category2};
  --category2-08: #{$hippie-category2-08};
  --category2-06: #{$hippie-category2-06};
  --category3: #{$hippie-category3};
  --category3-08: #{$hippie-category3-08};
  --category3-06: #{$hippie-category3-06};
  --category4: #{$hippie-category4};
  --category4-08: #{$hippie-category4-08};
  --category4-06: #{$hippie-category4-06};
  --category5: #{$hippie-category5};
  --category5-08: #{$hippie-category5-08};
  --category5-06: #{$hippie-category5-06};
  --category6: #{$hippie-category6};
  --category6-08: #{$hippie-category6-08};
  --category6-06: #{$hippie-category6-06};
  --category7: #{$hippie-category7};
  --category7-08: #{$hippie-category7-08};
  --category7-06: #{$hippie-category7-06};
  --text: #{$hippie-text};
  --text-05: #{$hippie-text-05};
  --text-02: #{$hippie-text-02};
  --text-alt: #{$hippie-text-alt};
  --text-alt-05: #{$hippie-text-alt-05};
  --text-alt-02: #{$hippie-text-alt-02};
  --link: #{$hippie-link};
  --grey: #{$hippie-grey};
}