@use "../../../variables";

.filter-pill {
  padding: 5px 8px;
  border-radius: 20px;
  border: 1px solid;

  display: flex;
  align-items: center;
  width: auto;

  //margin: 0 5px;
  transition: background-color 0.3s ease-in-out;

  .circle {
    height: 9px;
    width: 9px;
    border-radius: 6px;
    margin-right: 5px
  }

  .text {
    white-space: nowrap;
  }


  &.active {
    .text {
      color: variables.$background;
    }

    .svg {
      stroke: variables.$background;
      fill: variables.$background;
    }

    &.cat1 {
      background-color: variables.$category1;
    }
    &.cat2 {
      background-color: variables.$category2;
    }
    &.cat3 {
      background-color: variables.$category3;
    }
    &.cat4 {
      background-color: variables.$category4;
    }
    &.cat5 {
      background-color: variables.$category5;
    }
    &.cat6 {
      background-color: variables.$category6;
    }
    &.cat7 {
      background-color: variables.$category7;
    }
    &.link {
      background-color: variables.$link;
      border-color: variables.$link;
    }
  }

  &.cat1 {
    border-color: variables.$category1;
    .circle {
      background-color: variables.$category1;
    }
  }
  &.cat2 {
    border-color: variables.$category2;
    .circle {
      background-color: variables.$category2;
    }
  }
  &.cat3 {
    border-color: variables.$category3;
    .circle {
      background-color: variables.$category3;
    }
  }
  &.cat4 {
    border-color: variables.$category4;
    .circle {
      background-color: variables.$category4;
    }
  }
  &.cat5 {
    border-color: variables.$category5;
    .circle {
      background-color: variables.$category5;
    }
  }
  &.cat6 {
    border-color: variables.$category6;
    .circle {
      background-color: variables.$category6;
    }
  }
  &.cat7 {
    border-color: variables.$category7;
    .circle {
      background-color: variables.$category7;
    }
  }
  &.card {
    border-color: variables.$card;
    .circle {
      background-color: variables.$card;
    }
  }
  &.link {
    border-color: variables.$text-05;
    .circle {
      background-color: variables.$link;
    }
  }
}

.filter-pill + .filter-pill {
  margin-left: 10px;
}

.theme-dark .filter-pill {
  &.active.cat1 .text { color: variables.$text; }
  &.active.cat2 .text { color: variables.$text; }
  &.active.cat4 .text { color: variables.$text; }
}

.theme-light .filter-pill {
  &.active.cat3 .text { color: variables.$text; }
}

.theme-jungle .filter-pill {
  &.active.cat1 .text { color: variables.$text; }
  &.active.cat2 .text { color: variables.$text; }
  &.active.cat4 .text { color: variables.$text; }
  &.active.cat5 .text { color: variables.$text; }
  &.active.cat7 .text { color: variables.$text; }
}

.theme-balearic .filter-pill {
  &.active.cat3 .text { color: variables.$text; }
}

.theme-rave .filter-pill {
  &.active.cat2 .text { color: variables.$text; }
  &.active.cat4 .text { color: variables.$text; }
}

.theme-hippie .filter-pill {
  &.active.cat3 .text { color: variables.$text; }
}

.theme-pastel .filter-pill {
  &.active .text { color: variables.$text; }
}
