@use "../../../variables.scss";

.timeline-canvas {
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .react-flow__node-TimelinePill {
    transition: transform 0.5s ease-in-out;
  }

  .react-flow__attribution {
    display: none;
  }

}

.theme-dark .timeline-canvas {}
.theme-light .timeline-canvas {}
.theme-baby .timeline-canvas {
  .timeline-years {
    .text {
      color: variables.$text-alt;
    }
  }
}