@use "../../../variables.scss";

.big-screen-disclaimer {
  position: fixed;
  width: 100dvw;
  height: 100dvh;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: variables.$background;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 10000;

  .window {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 12px;
    padding: 15px;

    width: 80dvw;
    max-width: 500px;

    background-color: variables.$card;
  }

  .hero-image {
    width: 120px;
    height: 120px;
  }

  .text + .text {
    margin-top: 15px;
  }

  a {
    margin-top: 25px;
  }
}
