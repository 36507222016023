@use "../../../variables.scss";

.page.bio-page {
  padding: 20px;

  .page-content {
    min-height: 100dvh;
  }

  .timeline-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;

    .links {
      display: flex;
      align-items: flex-start;
      max-width: 80vw;


      .svg-icon {
        margin-right: 12px;
      }
    }

    .links + .links {
      margin-top: 6px;
    }
  }
}

.desktop .page.bio-page {
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .desktop-card {
    //height: 600px;
    width: 300px;

    .about {
      display: flex;
      align-items: flex-start;
    }

    .card {
      background-color: variables.$card;
      border-radius: 12px;
      margin: 15px 0;
    }

    //background-color: variables.$card;
  }

  .grid-section {
    .filter-pills {

    }
  }
}