@use "../../variables";

$rave-background: #000000;
$rave-background-00: #00000000;
$rave-card: #222222;
$rave-card-04: #22222240;

$rave-text: #FFFFFF;
$rave-text-05: #FFFFFF50;
$rave-text-02: #FFFFFF20;
$rave-text-alt: #000000;
$rave-text-alt-02: #00000020;
$rave-text-alt-05: #00000050;
$rave-link: #FF00FF;

$rave-category1: #00FFFF;
$rave-category1-08: #00FFFF80;
$rave-category1-06: #00FFFF60;
$rave-category2: #FF0000;
$rave-category2-08: #FF000080;
$rave-category2-06: #FF000060;
$rave-category3: #FFFF00;
$rave-category3-08: #FFFF0080;
$rave-category3-06: #FFFF0060;
$rave-category4: #FF00FF;
$rave-category4-08: #FF00FF80;
$rave-category4-06: #FF00FF60;
$rave-category5: #FFA500;
$rave-category5-08: #FFA50080;
$rave-category5-06: #FFA50060;
$rave-category6: #00FF00;
$rave-category6-08: #00FF0080;
$rave-category6-06: #00FF0060;
$rave-category7: #FFD700;
$rave-category7-08: #FFD70080;
$rave-category7-06: #FFD70060;

$rave-grey: #161529;

.theme.theme-rave {
  --background: #{$rave-background};
  --background-00: #{$rave-background-00};
  --card: #{$rave-card};
  --card-04: #{$rave-card-04};
  --category1: #{$rave-category1};
  --category1-08: #{$rave-category1-08};
  --category1-06: #{$rave-category1-06};
  --category2: #{$rave-category2};
  --category2-08: #{$rave-category2-08};
  --category2-06: #{$rave-category2-06};
  --category3: #{$rave-category3};
  --category3-08: #{$rave-category3-08};
  --category3-06: #{$rave-category3-06};
  --category4: #{$rave-category4};
  --category4-08: #{$rave-category4-08};
  --category4-06: #{$rave-category4-06};
  --category5: #{$rave-category5};
  --category5-08: #{$rave-category5-08};
  --category5-06: #{$rave-category5-06};
  --category6: #{$rave-category6};
  --category6-08: #{$rave-category6-08};
  --category6-06: #{$rave-category6-06};
  --category7: #{$rave-category7};
  --category7-08: #{$rave-category7-08};
  --category7-06: #{$rave-category7-06};
  --text: #{$rave-text};
  --text-05: #{$rave-text-05};
  --text-02: #{$rave-text-02};
  --text-alt: #{$rave-text-alt};
  --text-alt-05: #{$rave-text-alt-05};
  --text-alt-02: #{$rave-text-alt-02};
  --link: #{$rave-link};
  --grey: #{$rave-grey};
}