@use "../../../variables.scss";

.next-episode {
  padding: 5px 6px 5px 20px;
  background-color: variables.$card;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .release-date {
    padding: 6px 9px;
    background-color: variables.$background;
    border-radius: 8px;
  }
}

.next-episode + .next-episode {
  margin-top: 6px;
}