@use "../../../variables.scss";

.wallet-address {
  display: flex;
  padding: 7px 4px;
  justify-content: space-between;

  background-color: transparent;
  transition: background-color 0.3s;

  &.is-copying {
    background-color: variables.$text-alt-05;
  }

  .coin-name {
    display: flex;
    .svg-icon {
      margin-right: 15px;
    }
  }

  .copy-address {
    display: flex;
    align-items: center;

    .svg-icon {
      margin-left: 20px;
    }
  }

  .check-circle {
    fill: variables.$category2 !important;
  }
}

.wallet-address + .wallet-address {
  border-top: 1px solid variables.$text-alt-05;
}